var $ = require("jquery");
window.$ = window.jQuery = $;
require("bootstrap/dist/js/bootstrap.bundle");
var jQueryBridget = require('jquery-bridget');
var Flickity = require('flickity');
var Isotope = require('isotope-layout');
const smoothScroll = require('./assets/js/smooth-scroll.min.js')
Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);
jQueryBridget('isotope', Isotope, $);
window.smoothScroll = smoothScroll;
require('./assets/js/typed.min')
require('./assets/js/parallax')
require('./assets/js/ytplayer.min.js')
require('./assets/js/lightbox.min.js')
require('./assets/js/granim.min.js')
require('./assets/js/jquery.steps.min.js')
require('./assets/js/countdown.min.js')
require('./assets/js/twitterfetcher.min.js')
require('./assets/js/spectragram.min.js')
require('./assets/js/jquery-match-height.min.js')
require('./assets/js/scripts.js')
require('particles.js/particles.js')
window.Aos = require('aos/dist/aos.js')
require('aos/dist/aos.css')
require('./custom.js')